import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { Global } from  "@emotion/react"
import Seo from "../components/seo"
//import { SectionWrap } from "../components/styles"
//import Hero from "../components/Hero"
import theme from "../gatsby-plugin-theme-ui"
import { SectionWrap } from "../components/styles"
import { Header, Hero, Section } from "../components"
import { globalStyles } from "../templates/styles"
import { Styled } from "theme-ui"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"
import AppStores from "../components/AppStores.js"
import SearchBox from "../components/SeachBox.js"
export default function Template({}) {
  const data = useStaticQuery(graphql`
    query {
      allNounsJson {
        nodes {
          title
        }
      }
    }
  `)
  const nounsJson = data.allNounsJson.nodes

  const groups = nounsJson.reduce((groups, brand) => {
    const letterKey = brand.title.charAt(0).toLowerCase()
    ;(groups[letterKey] || (groups[letterKey] = [])).push(brand)
    return groups
  }, {})

  var nounContent = <div></div>

  return (
    <div style={{}}>
      <Seo
        title={`Browse Nouns, Article and Definitions with Examples`}
        description={`Declension of all nouns in German, all forms, plural, translation, definition, sentences with examples, all cases`}
        lang={"en"}
        canonical={`browse`}
        keywords={`der,die,das,articles,feminine,masculine,neuter,grammar,learn,german,nouns,training,game,substantive,substantive,vocabulary,ios,android,app,declension, plural, all forms, nominative`}
      />
      <Global styles={globalStyles} />
      <Header logoTxt={"Der Die Das Train"} />
      <SectionWrap style={{ backgroundColor: "white" }}>
        <Hero imageAlt={"hero.imageAlt"} id="hero" />
        <div style={{}}>
          <SearchBox></SearchBox>

          <Section containerId={"section-inner-container"}>
            {Object.keys(groups).map((letterKey, index) => {
              return (
                <div>
                  {" "}
                  <h3>{letterKey.toUpperCase()} </h3>
                  <div style={{display:'flex',flexWrap:'wrap'}}>
                  {groups[letterKey].map((noun, index) => {
                    return <div style={{padding:20}}>
                        <Link to={`/${noun.title.replace(/ /g,'_')}`} >{noun.title}</Link>

                        </div>
                  })}
                  </div>
                </div>
              )
            })}
          </Section>
        </div>
        <Section
          backgroundColor={theme.colors.secondary}
          id="about"
          light
          enableWave={true}
          waveBottom={false}
        >
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <AppStores></AppStores>
          </div>
        </Section>
      </SectionWrap>
    </div>
  )
}

var tableStyle = `<style>
#table-outer {
  margin-bottom: 0em;
}

.wikitable > tr > th, .wikitable > * > tr > th {
  background-color: #eaecf0;
  text-align: center;
}

.wikitable > tr > th, .wikitable > tr > td, .wikitable > * > tr > th, .wikitable > * > tr > td {
  border: 1px solid #a2a9b1;
  padding: 0.2em 0.4em;
}
th {

  font-weight: bold;
  text-align: -internal-center;
}
.wikitable {
  width:auto;
  background-color: #f8f9fa;
  color: #202122;
 /* border: 1px solid #a2a9b1;*/
  border-collapse: separate;
  border-radius: 10px !important;


}
table {
  font-size: 100%;
  max-width:100%;
  width:auto;
}
@media only screen and (max-width: 600px) {
  #table-outer {
    margin-bottom: 22em;
  }
}
</style>`

const SpanText = styled.span`
  color: ${"gray"};
`

const SpeakerDiv = styled.div`
  background-color: ${"white"};
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  margin-bottom: 10px;
`
